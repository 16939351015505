import React, { useEffect, useState } from "react"

import { useLocation } from "@reach/router"

import WebsiteLayout from '../components/site/website_layout'
import FourOhFour from '../components/site/404'

import routes, {navigate} from "../services/routes"

export default () => {
  const [state, setState] = useState({giveUp: false});

  const location = useLocation();

  useEffect(() => {
    // did the user paste in an old board url of the form /board/:boardId ?
    if (location.pathname.startsWith("/board/") && location.pathname !== "/board/") {
      const boardId = location.pathname.substr("/board/".length);
      navigate(routes.board(boardId), { replace: true });
    }

    // if we haven't left in 3 seconds, display the 404
    const timeout = setTimeout(() => {
      setState({...state, giveUp: true});
    }, 3000);
    return () => { clearTimeout(timeout); };
  }, []);

  if (state.giveUp) {
    return (
      <WebsiteLayout>
        <FourOhFour />
      </WebsiteLayout>
    );
  } else {
    return <>Loading...</>;
  }
}